import { render, staticRenderFns } from "./FishingPermitNeedTable.vue?vue&type=template&id=2df2f482&scoped=true&lang=true&"
import script from "./FishingPermitNeedTable.vue?vue&type=script&lang=js&"
export * from "./FishingPermitNeedTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2df2f482",
  null
  
)

export default component.exports